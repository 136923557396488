import 'bootstrap/dist/css/bootstrap.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import * as VKID from '@vkid/sdk';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './store'
import { I18nextProvider } from 'react-i18next';
import i18next from './i18next';


const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
const addJq = () => {
  const script = document.createElement("script");
  script.src = "https://code.jquery.com/jquery-3.7.1.js";
  script.integrity = "sha256-eKhayi8LEQwp4NKxN+CfCh+3qOVUtJn3QNZ0TciWLP4==";
  script.crossOrigin = "anonymous";
  document.head.appendChild(script);

  const scriptYM = document.createElement("script")
  scriptYM.src = '/ym.js'
  scriptYM.type = 'text/javascript'

  document.head.appendChild(scriptYM);

  const scriptCT = document.createElement("script");
  scriptCT.type = 'text/javascript'
  scriptCT.append(`(function(w,d,n,c){w.CalltouchDataObject=n;w[n]=function(){w[n]["callbacks"].push(arguments)};if(!w[n]["callbacks"]){w[n]["callbacks"]=[]}w[n]["loaded"]=false;if(typeof c!=="object"){c=[c]}w[n]["counters"]=c;for(var i=0;i<c.length;i+=1){p(c[i])}function p(cId){var a=d.getElementsByTagName("script")[0],s=d.createElement("script"),i=function(){a.parentNode.insertBefore(s,a)},m=typeof Array.prototype.find === 'function',n=m?"init-min.js":"init.js";s.type="text/javascript";s.async=true;s.src="https://mod.calltouch.ru/"+n+"?id="+cId;if(w.opera=="[object Opera]"){d.addEventListener("DOMContentLoaded",i,false)}else{i()}}})(window,document,"ct","2leyj89q");`)
  document.head.appendChild(scriptCT);

}
addJq()

root.render(
  <Provider store={store}>
    <BrowserRouter basename={baseUrl}>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </BrowserRouter>
  </Provider>
)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
