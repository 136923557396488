import { configureStore } from '@reduxjs/toolkit'
import { callApi } from './features/CallApi'
import tokenReducer from './features/tokenSlice'
import refreshTokenReducer from './features/refreshTokenSlice'
import clientTokenReducer from './features/tokenClientSlice'
import refreshClientTokenReducer from './features/refreshClientTokenSlice'
import clientAuthTokenReducer from './features/tokenAuthClientSlice'
import refreshClientAuthTokenReducer from './features/refreshAuthClientTokenSlice'

// import selectionReducer from './features/selectionSlice'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import { callAppApi } from './features/CallAppApi'
import { callAuthApi } from './features/CallAuthApi'
// Or from '@reduxjs/toolkit/query/react'

export const store = configureStore({
  reducer: {
    token: tokenReducer,
    refreshToken: refreshTokenReducer,
    clientToken: clientTokenReducer,
    clientRefreshToken: refreshClientTokenReducer,
    clientAuthTokenReducer: refreshClientAuthTokenReducer,
    // selection: selectionReducer,
    // Add the generated reducer as a specific top-level slice
    [callApi.reducerPath]: callApi.reducer,
    [callAppApi.reducerPath]: callAppApi.reducer,
    [callAuthApi.reducerPath]: callAuthApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(callApi.middleware)
      .concat(callAppApi.middleware)
      .concat(callAuthApi.middleware),
})

setupListeners(store.dispatch)