import { Button, ButtonGroup, Col, Container, Form, InputGroup, Row, Spinner } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { useGetSchemeHallQuery, usePutSelectedSeatsEditMutation } from "../../../features/CallApi"
import { LoadScreenMin } from "../../UI/LoadScreenMin"
import { useEffect, useState } from "react"
import { MainStage } from "../../SchemeHall/MainStage"
import { SchemeSetingsEdit } from "./SchemeSetingsEdit"
import { SectorAdd } from "./Sector/SectorAdd"
import { RowAdd } from "./Rows/RowAdd"
import { AddSeats } from "./Seats/AddSeats"
import { TextList } from "./Texts/TextList"
import { SectorList } from "./Sector/SectorList"
import { StatictObjectsList } from "./StatictObjects/StatictObjectsList"
import { useForm } from "react-hook-form"
import { Check, XCircle } from "react-bootstrap-icons"



const SelectedSeatsEdit = ({ selectedSeatsIds, setSelectedSeatsIds, schemeHallId, refetch }) => {

    const { register, handleSubmit, reset, formState: { errors, isValid }, setValue } = useForm();
    const [showSeatsEdit, setShowSeatsEdit] = useState(false);
    const [putSelectedSeatsEdit, { isLoading: isPutingSeatsEdit }] = usePutSelectedSeatsEditMutation()
    const submitEditSeats = (data) => {
        const newParamSeats = {
            offsetX: data.offsetX,
            offsetY: data.offsetY,
            schemeHallId: schemeHallId,
            type: data.type,
            seatsIds: selectedSeatsIds
        }
        console.log(newParamSeats)
        putSelectedSeatsEdit(newParamSeats)
            .then(() => {
                reset()
                refetch()
            })
    }

    useEffect(() => {
        if (selectedSeatsIds.length === 0) {
            setShowSeatsEdit(false)
            reset()
        }
    }, [selectedSeatsIds])

    const clearSeletedSeats = () => {
        setSelectedSeatsIds([])
        reset()
    }

    return (
        <>
            {selectedSeatsIds.length > 0 &&
                <ButtonGroup
                    className="d-flex"
                    size="sm">
                    <Button
                        className="w-100"
                        onClick={() => {
                            setShowSeatsEdit(!showSeatsEdit)
                        }
                        }
                        disabled={selectedSeatsIds.length > 0 ? false : true}
                    >Изменить выбранные</Button>
                    <Button
                        onClick={() => { clearSeletedSeats() }}
                        variant="secondary"
                        disabled={selectedSeatsIds.length > 0 ? false : true}
                    >
                        <XCircle />
                    </Button>
                </ButtonGroup>
            }
            {showSeatsEdit &&
                <Form className="mb-3 mt-1 border-bottom pb-1" onSubmit={handleSubmit(submitEditSeats)}>
                    {!isPutingSeatsEdit ?
                        <>
                            <div>Редактирование ряда</div>

                            <InputGroup className="mb-3 mt-1" size="sm">
                                <InputGroup.Text>X±</InputGroup.Text>
                                <Form.Control {...register("offsetX")} defaultValue={0} type='number' />
                                <InputGroup.Text>Y±</InputGroup.Text>
                                <Form.Control {...register("offsetY")} defaultValue={0} type="number" />
                                <Button variant="success" type="submit"><Check /></Button>
                            </InputGroup>
                            <InputGroup size="sm">
                                <Form.Select size="sm" {...register("type")}>
                                    <option value={"noEdit"}>--- тип ---</option>
                                    <option value={"singl"}>Одиночное</option>
                                    <option value={"topSingl"}>Вниз одиночное</option>
                                    <option value={"left"}>Левый</option>
                                    <option value={"right"}>Правый</option>
                                    <option value={"turnLeft"}>Влево</option>
                                    <option value={"turnRight"}>Вправо</option>
                                    <option value={"boxCenter"}>Центр ложи</option>
                                    <option value={"boxTopLeft"}>Верх-лева ложи</option>
                                    <option value={"boxTopRight"}>Верх-право ложи</option>
                                    <option value={"topLeft"}>Вниз левый</option>
                                    <option value={"topRight"}>Вниз правый </option>
                                </Form.Select>
                            </InputGroup>
                        </>
                        :
                        <Spinner />
                    }

                </Form>
            }
        </>
    )
}

export const SchemeEdit = () => {

    const { id } = useParams()
    const { data, isLoading, refetch } = useGetSchemeHallQuery(id)
    const [showSetingsEdit, setShowSetingsEdit] = useState(false)
    const [showSectorAdd, setShowSectorAdd] = useState(false)
    // const [showSectorEdit, setShowSectorEdit] = useState(false)
    const [addRowShow, setAddRowShow] = useState(false)
    const [reloadScheme, setReloadScheme] = useState(true)
    const [sectionsId, setSectionsId] = useState()
    const [rowId, setRowId] = useState()
    const [addSeatsShow, setAddSeatsShow] = useState(false)
    const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 })
    const [selectedSeatsIds, setSelectedSeatsIds] = useState([]);

    useEffect(() => {
        if (!reloadScheme) {
            setReloadScheme(true)
        }
    }, [data])
    useEffect(() => {
        if (reloadScheme) {
            setTimeout(() => { setReloadScheme(false) }, 500)
        }
    }, [reloadScheme])
    // useEffect(() => {
    //     if (data) {
    //         console.log(data)
    //         if (data.staticObjects) {
    //             data.staticObjects.forEach(object => {
    //                 object.ctxs.forEach(ctxs => {
    //                     console.log(ctxs)
    //                 });
    //             });
    //         }
    //     }
    // }, [data])


    return (
        <Container fluid>
            {showSetingsEdit && <SchemeSetingsEdit refetch={refetch} data={data} showSetingsEdit={showSetingsEdit} setShowSetingsEdit={setShowSetingsEdit} />}
            {showSectorAdd && <SectorAdd refetch={refetch} schemeHallId={data.id} showSectorAdd={showSectorAdd} setShowSectorAdd={setShowSectorAdd} />}
            {addRowShow && <RowAdd
                refetch={refetch}
                sectionsId={sectionsId}
                addRowShow={addRowShow}
                setAddRowShow={setAddRowShow}
            />}
            {addSeatsShow && <AddSeats
                schemeHallId={data.id}
                addSeatsShow={addSeatsShow}
                setAddSeatsShow={setAddSeatsShow}
                rowId={rowId}
                refetch={refetch} />}
            {!isLoading ?
                <>
                    <div className="fs-5">Схема "{data.name}"</div>
                    <Row>
                        <Col lg={4} md={5} xl={3} className={'border-end border-bottom pb-2'}>
                            <Row>
                                <Col xs={12}>Кординаты курсора</Col>
                                <Col>x: {Math.round(cursorPos.x)}</Col>
                                <Col>y: {Math.round(cursorPos.y)}</Col>
                            </Row>
                            <ButtonGroup className="mb-1 mt-3 w-100">
                                <Button size="sm" className="w-100" onClick={() => { setShowSetingsEdit(!showSetingsEdit) }}>Настройки схемы</Button>
                            </ButtonGroup>
                            {data.sections.length < 1 &&
                                <div className="mb-1">
                                    <Button size="sm" className="w-100" onClick={() => { setShowSectorAdd(!showSectorAdd) }}>Добавить сектор</Button>
                                </div>
                            }
                            {data.sections.length > 0 &&
                                <div className="mb-1">
                                    <SelectedSeatsEdit schemeHallId={id} refetch={refetch} selectedSeatsIds={selectedSeatsIds} setSelectedSeatsIds={setSelectedSeatsIds} />
                                </div>
                            }
                            {data.sections.length > 0 ?
                                <div style={{ overflowY: "scroll", overflowX: "hidden", maxHeight: "65vh" }}>

                                    <SectorList
                                        sections={data.sections}
                                        schemeHallId={data.id}
                                        refetch={refetch}
                                        setAddRowShow={setAddRowShow}
                                        setSectionsId={setSectionsId}
                                        setRowId={setRowId}
                                        addSeatsShow={addSeatsShow}
                                        setAddSeatsShow={setAddSeatsShow}

                                    />
                                    <TextList refetch={refetch} schemeId={data.id} texts={data.texts} />
                                    <StatictObjectsList schemeId={data.id} refetch={refetch} staticObjects={data.staticObjects} />
                                </div>
                                : <div>Нет секторов</div>}
                        </Col>
                        <Col lg={8} md={7} xl={9}>
                            {!reloadScheme ?
                                <>
                                    <MainStage selectedSeatsIds={selectedSeatsIds} setSelectedSeatsIds={setSelectedSeatsIds} data={data} setCursorPos={setCursorPos} />
                                </>
                                :
                                <LoadScreenMin height={30} />}
                        </Col>
                    </Row>
                </>
                :
                <LoadScreenMin height={30} />
            }
        </Container>
    )
}