import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const callAuthApi = createApi({
    reducerPath: 'callAuthApi',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://auth.leningradcenter.ru/',
        // baseUrl: 'https://localhost:7149/',
        // prepareHeaders: (headers, { getState }) => {
        //     const token = getState().authToken.value;
        //     if (token) {
        //         headers.set("Authorization", `Bearer ${token.Token}`);
        //     }
        //     return headers;
        // },
    }),
    keepUnusedDataFor: 0,
    refetchOnMountOrArgChange: 0,
    endpoints: (builder) => ({
        baseToken: builder.mutation({ query: () => ('login/basic-token') }),
        clientLogin: builder.mutation({
            query: (dataLogin) => ({
                url: `login`,
                method: `POST`,
                headers: { "Authorization": `Bearer ${dataLogin.baseToken}` },
                body: dataLogin.body
            })
        }),
        clientIdentification: builder.mutation({
            query: (authBody) => ({
                url: `login/identification?pin=${authBody.pinCode}`,
                method: `POST`,
                headers: { "Authorization": `Bearer ${authBody.authToken}` },
                // body: data
            })
        }),
        clientVkAuthPost: builder.mutation({
            query: (data) => ({
                url: `login/vk-auth`,
                method: `POST`,
                body: data
            })
        })
    })
})

export const {
    useBaseTokenMutation,
    useClientLoginMutation,
    useClientIdentificationMutation,
    useClientVkAuthPostMutation,
} = callAuthApi