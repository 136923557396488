import { useForm } from "react-hook-form";
import { useState } from "react";
import { Alert, Button, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useBaseTokenMutation, useClientIdentificationMutation, useClientLoginMutation, useClientVkAuthPostMutation } from "../../features/CallAuthApi";
import ReactInputMask from "react-input-mask";
import { useEffect } from "react";
import { PinInput } from "react-input-pin-code";
import Countdown from "react-countdown";
import { ArrowLeftCircle } from "react-bootstrap-icons";
import * as VKID from '@vkid/sdk';


const SingInForm = ({ t, setShowPinInput, selLanguage, setAuthData, VKID, setClientTokenData, setAuthorized }) => {
    const { register, handleSubmit, reset, formState: { errors }, setValue, setError, clearErrors } = useForm();
    const [errorMsg, setErrorMsg] = useState(null)
    const login = (data) => {
        const phone = data.phone.replace(/[\s+\-_()]/g, '')
        // console.log(phone.length)
        if (phone.length !== 11) {
            setError("phone", { type: 'custom', message: 'Не верно указан номер' })
        }
        data.phone = phone

        baseToken().unwrap().then((r) => {
            const dataLogin = {
                body: data,
                baseToken: r.token
            }
            clientLogin(dataLogin).unwrap().then((r) => {
                setAuthData(r)
                reset()
                setShowPinInput(true)
            }).catch((e) => {
                console.log(e)
                if (e.data?.code === 105) {
                    setErrorMsg(`Код: ${e.data?.code},\nВ течение 24 часов можно отправить не более 3-х SMS.`)
                }
                else {
                    setErrorMsg(`Код: ${e.status}`)
                }
                // window.alert(`Code: ${e.status}\nTitle: ${e.data.title}`)
                // console.error(e)
            })
        }).catch((r) => {
            setErrorMsg("Ошибка подключения, проверте доступности сети")
        })
    }

    const [baseToken] = useBaseTokenMutation()
    const [clientLogin, { isLoading: isLoging }] = useClientLoginMutation()
    const [agreement, setAgreement] = useState(false)

    useEffect(() => {
        if (agreement) {
            clearErrors("agreement")
        }
    }, [agreement])
    // const [latestNewsInformation, setLatestNewsInformation] = useState(false)

    return (
        <div style={{ minHeight: '50vh', alignItems: 'center', display: 'grid' }}>
            <form onSubmit={handleSubmit(login)}>
                <Row className={'justify-content-center'}>
                    <Col md={6} lg={4}>
                        <h4 className="text-center">{t("form.loginToAccount")}</h4>
                        <Alert show={(errorMsg !== null)} variant="danger" onClose={() => { setErrorMsg(null) }} dismissible>
                            <p className="mb-0">{errorMsg}</p>
                        </Alert>
                        <div className="mb-1 form-floating">

                            <ReactInputMask
                                placeholder={t("form.phone")}
                                mask="+7 (999) 999-99-99"
                                name={t("form.phone")}
                                type='tel'
                                id="tel"
                                className={`form-control ${errors?.phone?.message != null && "is-invalid"}`}
                                {...register("phone", { required: { value: true, message: "Поле Телефон обязательное" } })}
                            />
                            <label htmlFor="tel">{t("form.phone")}</label>
                            <div className="text-muted ps-1 pr-1 mt-1" style={{ fontSize: '10px', textAlign: 'justify' }}>
                                {t("form.dectSmsForm")}
                            </div>
                            {errors?.phone?.message &&
                                <div className="invalid-feedback">
                                    {errors?.phone?.message}
                                </div>
                            }
                            {selLanguage === "en" ?
                                <>
                                    <div className="form-check form-switch mt-3">
                                        <input className={`form-check-input check-input-lc ${errors.agreement && "is-invalid"}`} onClick={() => { setAgreement(!agreement) }}
                                            checked={agreement}
                                            type="checkbox"
                                            id="agreement" {...register("agreement", { required: true })} />
                                        <label className="form-check-label" htmlFor="agreement">
                                            I have read the Rules on Purchasing Tickets, the rules of visiting Leningrad Center and the Policy on Processing Personal Data.
                                        </label>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="form-check form-switch mt-3">
                                        <input className={`form-check-input check-input-lc ${errors.agreement && "is-invalid"}`} onClick={() => { setAgreement(!agreement) }}
                                            checked={agreement}
                                            type="checkbox"
                                            id="agreement" {...register("agreement", { required: true })} />
                                        <label className="form-check-label" htmlFor="agreement">
                                            Я ознакомлен и согласен с <a href="/api/redirecturls/ticket-purchase-rules" target="_blank">Правилами покупки билетов</a>, <a target="_blank" href="/api/redirecturls/visiting-rules">Правилами посещения Ленинград Центра</a> и <a href="/api/redirecturls/privacy-policy" target="_blank">Политикой обработки персональных данных</a>.
                                        </label>
                                    </div>
                                </>
                            }
                        </div>
                        <Button type="submit" className="btn-lc btn-lc-rounded w-100 mt-3">{t("form.singIn")} {isLoging && <Spinner size="sm" />}</Button>
                    </Col>
                </Row>
            </form>
            <VkIdSingIn t={t} VKID={VKID} setError={setError} agreement={agreement} setAgreement={setAgreement} setClientTokenData={setClientTokenData} setAuthorized={setAuthorized} />
        </div>
    )
}

const PinCodeForm = ({ t, setClientTokenData, setAuthorized, authData, setShowPinInput }) => {

    const navigate = useNavigate()
    const [clientIdentification, { isLoading }] = useClientIdentificationMutation()
    const [disableBtn, setDisableBtn] = useState(true)
    const renderer = ({ formatted }) => {
        return <span>{formatted.minutes}:{formatted.seconds}</span>;
    }
    const [pinValues, setPinValues] = useState(['', '', '', '']);
    const [pinError, setPinError] = useState(null)
    const [autoFocus, setAutoFocus] = useState(true)
    const [btnResendShow, setBtnResendShow] = useState(false)
    useEffect(() => {
        const pin = pinValues.join("")
        if (pin.length === 4) {
            const authBody = { pinCode: pin, authToken: authData.token }
            setDisableBtn(false)
            clientIdentification(authBody).unwrap().then((r) => {
                if (r.error) {
                    throw r.error
                }

                const data = {
                    Token: r.token,
                    DeviceId: r.deviceId,
                    RefreshToken: r.refreshToken,
                    ExpiresIn: r.expiresIn,
                    RefreshExpiresIn: r.refreshExpiresIn,
                    Role: r.role
                }
                console.log(data)
                setClientTokenData(data)
                setAuthorized(true)
                navigate("/client/profile")
            }).catch(() => {
                setAutoFocus(false)
                setPinError("Не верный пин код")
                setPinValues(['', '', '', ''])
            })
        }
        else {
            setDisableBtn(true)
        }
    }, [pinValues])

    useEffect(() => {
        if (!autoFocus) {
            setAutoFocus(true)
        }
    }, [pinError])

    return (
        <Row className={'justify-content-center'}>
            <Col md={6} lg={4}>
                <Card>
                    <Card.Body>
                        <form style={{ minHeight: '40vh', alignItems: 'center', display: 'grid', textAlign: 'center' }}>
                            <div>
                                <div style={{ textAlign: 'left' }}>
                                    <Button variant="light" className="p-1" onClick={() => { setShowPinInput(false) }}>
                                        <ArrowLeftCircle size={24} />
                                    </Button>
                                </div>
                                <h4>
                                    Подтвердите вход по смс
                                </h4>
                                <p>
                                    Код подтверждения отправлен на номер {authData.phone}
                                </p>
                                <PinInput
                                    autoFocus={autoFocus}
                                    containerClassName="justify-content-center"
                                    inputClassName={pinError ? "pin-is-invalid" : ""}
                                    // className="pin-is-invalid is-invalid"
                                    values={pinValues}
                                    placeholder="*"
                                    onChange={(pinValue, index, values) => {
                                        setPinError(null)
                                        setPinValues(values)
                                    }}
                                />
                                <div className="pin-is-invalid">
                                    {pinError}
                                </div>
                                <Button disabled={disableBtn} type="submit" className="btn-lc btn-lc-rounded w-100 mt-3">{t("form.singIn")} {isLoading && <Spinner size="sm" />}</Button>
                                {!btnResendShow ?
                                    <div className="mt-3">
                                        Повторно отправить смс можно будет через:
                                        <Countdown
                                            date={Date.now() + 1000 * 60 * 3}
                                            autoStart={true}
                                            renderer={renderer}
                                            onComplete={() => { setBtnResendShow(true) }}
                                            zeroPadTime={2}
                                        />
                                    </div>
                                    :
                                    <Button type="button" className="mt-3 btn-lc-rounded w-100" variant="light">Отправить код повторно</Button>
                                }
                            </div>
                        </form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

const VkIdSingIn = ({ t, setError, agreement,  setClientTokenData, setAuthorized }) => {

    const params = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const location = useLocation()
    const [clientVkAuthPost, { isLoading }] = useClientVkAuthPostMutation()


    const generateRandomString = (length = 64) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_-';
        let result = '';

        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }

        return result;
    }

    function base64URLEncode(str) {
        return btoa(str)
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=/g, '');
    }

    function sha256(buffer) {
        return crypto.subtle.digest('SHA-256', buffer).then(hash => {
            return new Uint8Array(hash);
        });
    }

    async function generateCodeChallenge(codeVerifier) {
        const encoder = new TextEncoder();
        const data = encoder.encode(codeVerifier);
        const hashBuffer = await sha256(data);
        return base64URLEncode(String.fromCharCode(...hashBuffer));
    }

    // Обработчик клика.
    const handleClick = async () => {

        if (!agreement) {
            setError("agreement")
            return
        }

        const code_verifier = generateRandomString(64)
        // localStorage.setItem("code_verifier", code_verifier)
        const state = "myState" + generateRandomString(32)
        const code_challenge = await generateCodeChallenge(code_verifier)
        const redirect_url = `https://leningradcenter.ru/client/vk-auth`
        // console.log(code_verifier)
        // console.log(code_challenge)
        // Открытие авторизации.
        VKID.Config.init({
            app: 52716905, // Идентификатор приложения.
            redirectUrl: redirect_url, // Адрес для перехода после авторизации.
            state: state, // Произвольная строка состояния приложения.
            codeChallenge: code_challenge, // codeVerifier, преобразованный по алгоритму S256. Представялет собой случайную строку. Обеспечивает защиту передаваемых данных.
            scope: 'vkid.personal_info email phone', // Список прав доступа, которые нужны приложению.
            responseMode: VKID.ConfigResponseMode.Callback
        });
        const vkAuth = VKID.Auth.login()

        vkAuth.then((vkR) => {
            const searchData = {
                code_verifier: code_verifier,
                code: vkR.code,
                deviceid: vkR.device_id,
                state: vkR.state,
            }
            console.log(searchData)
            // localStorage.removeItem("code_verifier")

            clientVkAuthPost(searchData).unwrap().then((r) => {
                if (r.error) {
                    throw r.error
                }
                const data = {
                    Token: r.token,
                    DeviceId: r.deviceId,
                    RefreshToken: r.refreshToken,
                    ExpiresIn: r.expiresIn,
                    RefreshExpiresIn: r.refreshExpiresIn,
                    Role: r.role
                }
                console.log(data)
                setClientTokenData(data)
                // setAuthorized(true)
            })
        }).catch(
            (e) => { console.error(e) }
        )
    }

    return (
        <>
            <Row className={'justify-content-center'}>
                <Col md={6} lg={4} className="text-center">
                    <span className="text-muted mt-1 mb-1">или</span>
                </Col>
            </Row>
            <Row className={'justify-content-center'}>
                <Col md={6} lg={4} className="text-center">
                    <button id="VKIDSDKAuthButton" className="VkIdWebSdk__button VkIdWebSdk__button_reset" onClick={() => { handleClick() }}>
                        <div className="VkIdWebSdk__button_container">
                            <div className="VkIdWebSdk__button_icon">
                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M4.54648 4.54648C3 6.09295 3 8.58197 3 13.56V14.44C3 19.418 3 21.907 4.54648 23.4535C6.09295 25 8.58197 25 13.56 25H14.44C19.418 25 21.907 25 23.4535 23.4535C25 21.907 25
           19.418 25 14.44V13.56C25 8.58197 25 6.09295 23.4535 4.54648C21.907 3 19.418 3 14.44 3H13.56C8.58197 3 6.09295 3 4.54648 4.54648ZM6.79999 10.15C6.91798 15.8728 9.92951 19.31 14.8932 19.31H15.1812V16.05C16.989 16.2332 18.3371
           17.5682 18.8875 19.31H21.4939C20.7869 16.7044 18.9535 15.2604 17.8141 14.71C18.9526 14.0293 20.5641 12.3893 20.9436 10.15H18.5722C18.0747 11.971 16.5945 13.6233 15.1803 13.78V10.15H12.7711V16.5C11.305 16.1337 9.39237 14.3538 9.314 10.15H6.79999Z" fill="white" />
                                </svg>
                            </div>
                            <div className="VkIdWebSdk__button_text">
                                Войти с VK ID
                            </div>
                        </div>
                    </button>
                </Col>
            </Row>
        </>
    )
}

export const ClientLoginSms = ({ setClientTokenData, t, setAuthorized, selLanguage, VKID }) => {
    const [showPinInput, setShowPinInput] = useState(false)
    const [authData, setAuthData] = useState({})

    return (
        <>
            <Container>
                {!showPinInput ?
                    <>
                        <SingInForm
                            VKID={VKID}
                            t={t}
                            setShowPinInput={setShowPinInput}
                            selLanguage={selLanguage}
                            setAuthData={setAuthData}
                            setClientTokenData={setClientTokenData}
                            setAuthorized={setAuthorized}
                        />
                    </>
                    :
                    <>
                        <PinCodeForm t={t}
                            setShowPinInput={setShowPinInput}
                            setClientTokenData={setClientTokenData}
                            setAuthorized={setAuthorized}
                            authData={authData} />
                    </>
                }
            </Container>
        </>
    )
}
