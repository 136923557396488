import { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import { Helmet } from "react-helmet"
import { useSearchParams } from "react-router-dom"
import { LoadScreenMin } from "../UI/LoadScreenMin";
import { useClientVkAuthPostMutation } from "../../features/CallAuthApi";


export const ClientVkAuht = ({ t, setClientTokenData,setAuthorized }) => {

    const [searchParams] = useSearchParams()
    const [clientVkAuthPost, { isLoading }] = useClientVkAuthPostMutation()

    useEffect(() => {
        console.log(searchParams)
        const code_verifier = localStorage.getItem("code_verifier")
        if (code_verifier === null) {
            return;
        }
        const searchData = {
            code_verifier: code_verifier,
            code: searchParams.get("code"),
            deviceid: searchParams.get("device_id"),
            state: searchParams.get("state"),
        }
        localStorage.removeItem("code_verifier")

        clientVkAuthPost(searchData).unwrap().then((r) => {
            if (r.error) {
                throw r.error
            }
            const data = {
                Token: r.token,
                DeviceId: r.deviceId,
                RefreshToken: r.refreshToken,
                ExpiresIn: r.expiresIn,
                RefreshExpiresIn: r.refreshExpiresIn,
                Role: r.role
            }
            console.log(data)
            setClientTokenData(data)
        })
    }, [searchParams])


    return (
        <>
            <Helmet>
                <title>{t("mainTag.title")}</title>
                <meta name="description" content='Первое в России шоу-пространство "Ленинград Центр"'></meta>
                <meta name="og:title" content={`${t("mainTag.title")}`} />
                <meta name="og:description" content='Первое в России шоу-пространство "Ленинград Центр"' />
                <meta name="keywords" content="шоу- пространство, шоу пространство, ленинград центр, центр ленинград, мюзикл, шоу, представление, афиша" c />
                {/* <meta property="og:image" content={data.headLayoutImageType ? `/api/shows/head-wide-img/${data.id})` : "/Show/091A8332.jpg"} /> */}
            </Helmet>
            <div className="about-haed-layout mb-3">
                <div className="about-head-layout-img" style={{ backgroundImage: `url("/AboutProjcet/about-project.jpg")` }}>
                </div>
                <div className="about-head-mask">
                    <div className="justify-content-end ms-auto me-auto justify-content-end rounded-3"
                        style={{ width: "70%", backgroundColor: "#00000006", padding: "1rem" }}>
                    </div>
                </div>
            </div>
            <Container>
                {isLoading &&
                    <LoadScreenMin height={30} />
                }
            </Container>
        </>
    )
}