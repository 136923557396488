import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: null,
};

const refreshAuthClientTokenSlice = createSlice({
  name: "refreshClientToken",
  initialState,
  reducers: {
    setRefreshAuthClientTokenInStore: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setAuthRefreshClientTokenInStore } = refreshAuthClientTokenSlice.actions;

export default refreshAuthClientTokenSlice.reducer;
