import { Button, ButtonGroup, Card, Col, Container, Form, Modal, Offcanvas, OffcanvasBody, Row, Spinner } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import { Fragment, useEffect, useState } from "react"
import { useGetPaySchemeQuery, useGetSessionDetailsQuery } from "../../features/CallApi"
import { LoadScreenMin } from "../UI/LoadScreenMin"
import { format } from "date-fns";
import { ru, enUS } from "date-fns/locale";
import { useTranslation } from "react-i18next"
import { ArrowLeftCircle, Calendar3Week, QuestionCircle, XCircle } from "react-bootstrap-icons"
import { useCreateNewTicketsOrderMutation, useGetclientDataQuery } from "../../features/CallAppApi"
import { useForm } from "react-hook-form"
import styles from './Show.module.css';
import { StageV2 } from "../SchemeHall/StageV2"
import ReactInputMask from "react-input-mask"
import { useBaseTokenMutation, useClientIdentificationMutation, useClientLoginMutation } from "../../features/CallAuthApi"
import { PinInput } from "react-input-pin-code"
import Countdown from "react-countdown"


const CategoresDetails = ({ data, showModal, setShowModal, t, selLanguage }) => {

    return (
        <Modal show={showModal} onHide={() => { setShowModal(false) }}>
            <Modal.Header closeButton>
                {t("payscheme.whatinPrice")}
            </Modal.Header>
            <Modal.Body>
                {data.map(
                    category =>
                        <div key={category.id} className="border-bottom pt-2 pb-2">
                            <div style={{ color: category.fill, alignItems: 'center' }} className="d-flex">
                                <div style={{ width: "16px", height: "16px", background: category.fill, borderRadius: '2px 2px 5px 5px' }} className="me-2">
                                </div>{selLanguage === 'en' ? category.nameEn : category.name}</div>
                            <div>{category.price} ₽</div>
                            <div style={{ fontSize: "12px" }}>
                                {selLanguage === 'en' ? category.descriptionEn : category.description}
                            </div>
                        </div>
                )}
            </Modal.Body>
        </Modal>
    )

}

const SchedulesModal = ({ showModal, t, schedules, setShedulesModal, id }) => {

    const navigate = useNavigate()

    return (
        <>
            <Modal
                show={showModal}
                size="md"
                fullscreen={"sm-down"}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // backdrop="static"
                onHide={() => {
                    setShedulesModal(false)
                }}
                onShow={() => {
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t("afisha.selectdate")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {schedules &&
                        <>
                            {schedules.map(item =>
                                <Card key={item.sessionId} className='mb-3 shadow' body>
                                    <Row>
                                        <Col>
                                            <div>
                                                Дата: {format(new Date(item.startOfEvent), 'dd.MM')} ({format(new Date(item.startOfEvent), 'EEEE', { locale: ru })})
                                            </div>
                                            <div>
                                                Начало: {format(new Date(item.startOfEvent), 'HH:mm')}
                                            </div>
                                        </Col>
                                        <Col xs={"auto"}>
                                            <Button onClick={() => {
                                                navigate(`/paytickets/${item.sessionId}`)
                                                setShedulesModal(false)
                                            }} className='btn-afisha border-0'>{t("afisha.select")}</Button>
                                        </Col>
                                    </Row>
                                </Card>
                            )}
                        </>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

const PinCodeForm = ({ t, setClientTokenData, authData, setShowPinInput, setDataForToken }) => {

    const navigate = useNavigate()
    const [clientIdentification, { isLoading }] = useClientIdentificationMutation()
    const [disableBtn, setDisableBtn] = useState(true)
    const renderer = ({ formatted }) => {
        return <span>{formatted.minutes}:{formatted.seconds}</span>;
    }
    const [pinValues, setPinValues] = useState(['', '', '', '']);
    const [pinError, setPinError] = useState(null)
    const [autoFocus, setAutoFocus] = useState(true)
    const [btnResendShow, setBtnResendShow] = useState(false)
    useEffect(() => {
        const pin = pinValues.join("")
        if (pin.length === 4) {
            const authBody = { pinCode: pin, authToken: authData.token }
            setDisableBtn(false)
            clientIdentification(authBody).unwrap().then((r) => {
                // if (r.error) {
                //     console.log()
                //     throw r.error
                // }
                const data = {
                    Token: r.token,
                    DeviceId: r.deviceId,
                    RefreshToken: r.refreshToken,
                    ExpiresIn: r.expiresIn,
                    RefreshExpiresIn: r.refreshExpiresIn,
                    Role: r.role
                }
                // console.log(data)

                setClientTokenData(data)
                setDataForToken(data)
                setShowPinInput(false)
                // navigate("/client/profile")
            })
                .catch(() => {
                    setAutoFocus(false)
                    setPinError("Не верный пин код")
                    setPinValues(['', '', '', ''])
                })
        }
        else {
            setDisableBtn(true)
        }
    }, [pinValues])

    useEffect(() => {
        if (!autoFocus) {
            setAutoFocus(true)
        }
    }, [pinError])

    return (
        <Row className={'justify-content-center'}>
            <Col>
                <form style={{ minHeight: '40vh', alignItems: 'center', display: 'grid', textAlign: 'center' }}>
                    <div>
                        <div style={{ textAlign: 'left' }}>
                            <Button variant="light" className="p-1" onClick={() => { setShowPinInput(false) }}>
                                <ArrowLeftCircle size={24} />
                            </Button>
                        </div>
                        <h4>
                            Подтвердите вход по смс
                        </h4>
                        <p>
                            Код подтверждения отправлен на номер {authData.phone}
                        </p>
                        <PinInput
                            autoFocus={autoFocus}
                            containerClassName="justify-content-center"
                            inputClassName={pinError ? "pin-is-invalid" : ""}
                            // className="pin-is-invalid is-invalid"
                            values={pinValues}
                            placeholder="*"
                            onChange={(pinValue, index, values) => {
                                setPinError(null)
                                setPinValues(values)
                            }}
                        />
                        <div className="pin-is-invalid">
                            {pinError}
                        </div>
                        <Button disabled={disableBtn} type="submit" className="btn-lc btn-lc-rounded w-100 mt-3">{t("form.singIn")} {isLoading && <Spinner size="sm" />}</Button>
                        {!btnResendShow ?
                            <div className="mt-3">
                                Повторно отправить смс можно будет через:
                                <Countdown
                                    date={Date.now() + 1000 * 60 * 3}
                                    autoStart={true}
                                    renderer={renderer}
                                    onComplete={() => { setBtnResendShow(true) }}
                                    zeroPadTime={2}
                                />
                            </div>
                            :
                            <Button type="button" className="mt-3 btn-lc-rounded w-100" variant="light">Отправить код повторно</Button>
                        }
                    </div>
                </form>
            </Col>
        </Row>
    )
}

const OrderModal = ({
    showOrderModal,
    setShowOrderModal,
    basket,
    t,
    summOrder,
    setDeleteSeatData,
    sessionId,
    showName,
    startOfEvent,
    hallName,
    duration,
    ageCategory,
    setClientTokenData,
    setAuthorized }) => {

    useEffect(() => {
    }, [showOrderModal])
    const { data, isLoading, refetch } = useGetclientDataQuery()
    const [agreementChecked, setAgreementChecked] = useState(false)
    const [ticketRulesChecked, setTicketRulesChecked] = useState(false)
    const [error, setError] = useState()
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
    const [createNewTicketsOrder, { isLoading: isCreatingOrder }] = useCreateNewTicketsOrderMutation()
    const [baseToken] = useBaseTokenMutation()
    const [clientLogin, { isLoading: isLoging }] = useClientLoginMutation()
    const [errorMsg, setErrorMsg] = useState(null)
    const [showPinInput, setShowPinInput] = useState(false)
    const [authData, setAuthData] = useState({})
    const [dataForToken, setDataForToken] = useState()

    const createOrder = (dataForm) => {
        createNewTicketsOrder(dataForm)
            .then((r) => {
                if (r.error?.data?.codeError) {
                    setError({
                        messageError: r.error.data.messageError,
                        codeError: r.error.data.codeError,
                    })
                    return;
                }
                const tokenOrder = {
                    tokenOrder: r.data.tokenOrder,
                    sessionId: sessionId
                }
                const tokenOrderS = JSON.stringify(tokenOrder);
                localStorage.setItem("tokenOrder", tokenOrderS)
                const bankUrl = r.data.bankUrl;
                window.location.href = bankUrl;
                setShowOrderModal(false)
            })
    }
    const login = (data) => {
        const phone = data.phone.replace(/[\s+\-_()]/g, '')
        // console.log(phone.length)
        if (phone.length !== 11) {
            setError("phone", { type: 'custom', message: 'Не верно указан номер' })
        }
        data.phone = phone

        baseToken().unwrap().then((r) => {
            const dataLogin = {
                body: data,
                baseToken: r.token
            }
            clientLogin(dataLogin).unwrap().then((r) => {
                setAuthData(r)
                // reset()
                setShowPinInput(true)
            }).catch((e) => {
                console.log(e)
                if (e.data?.code === 105) {
                    setErrorMsg(`Код: ${e.data?.code},\nВ течение 24 часов можно отправить не более 3-х SMS.`)
                }
                else {
                    setErrorMsg(`Код: ${e.status}`)
                }
                // window.alert(`Code: ${e.status}\nTitle: ${e.data.title}`)
                // console.error(e)
            })
        }).catch((r) => {
            setErrorMsg("Ошибка подключения, проверте доступности сети")
        })
    }

    const submit = (dataForm) => {
        console.log(dataForm)
        if (dataForm.clientId) {
            createOrder(dataForm)
        }
        else {
            // console.log(dataForm)
            // login(dataForm.f)
            login(dataForm)
        }
    }
    const closeError = () => {
        basket.map(item => {
            setDeleteSeatData(item)
        });
        // reset()
        // setShowOrderModal(false)
    }

    useEffect(() => {
        if (dataForToken) {
            refetch()
            setDataForToken(null)
        }
    }, [dataForToken])

    useEffect(() => {
        setValue("sessionId", sessionId)
        setValue("orderItem", basket)
        setValue("NameInPremiera", showName)
        setValue("startOfEvent", startOfEvent)
        setValue("hallName", hallName)
        setValue("duration", duration)
        setValue("ageCategory", ageCategory)
    }, [showOrderModal])
    useEffect(() => {
        if (data) {
            setValue("policy", true)
            setAgreementChecked(true)
            setValue("clientId", data.id)
        }
    }, [data])

    return (
        <Offcanvas show={showOrderModal} placement="end" onHide={() => setShowOrderModal(false)}>
            <Form onSubmit={handleSubmit(submit)} style={{ overflowY: 'auto' }}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        {!data ?
                            <div className="fs-4 text-center m-auto">
                                <b>
                                    {t("form.unAuth")}
                                </b>
                            </div>
                            :
                            <div>
                                {t("payscheme.ConfPurchase")}
                            </div>
                        }
                    </Offcanvas.Title>
                </Offcanvas.Header>
                {!showPinInput ?
                    <Offcanvas.Body>
                        {!isLoading ?
                            <>
                                {!data ?
                                    <>
                                        <div>
                                            <p className="text-center">
                                                {t("form.prePayInformation")}
                                            </p>
                                        </div>
                                        <Form.Floating className="mb-3">
                                            <Form.Control
                                                id="firstname"
                                                type="text"
                                                placeholder={t('form.firstName')}
                                                {...register("firstname", { required: { value: true, message: "" } })}
                                            />
                                            <label htmlFor="firstname">{t('form.firstName')}</label>
                                            {errors.firstname && <span className='text-danger'>{errors.firstname?.message}</span>}
                                        </Form.Floating>
                                        <Form.Floating className="mb-3">
                                            <Form.Control
                                                id="lastname"
                                                type="text"
                                                placeholder={t('form.lastName')}
                                                {...register("lastname", { required: { value: true, message: "" } })}
                                            />
                                            <label htmlFor="lastname">{t('form.lastName')}</label>
                                            {errors.lastname && <span className='text-danger'>{errors.lastname?.message}</span>}
                                        </Form.Floating>
                                        <Form.Floating className="mb-3">
                                            <Form.Control
                                                id="floatingInputCustom"
                                                type="email"
                                                placeholder="E-Mail"
                                                {...register("email", { required: { value: true, message: "" } })}
                                            />
                                            <label htmlFor="floatingInputCustom">E-Mail</label>
                                            {errors.email && <span className='text-danger'>{errors.email?.message}</span>}
                                        </Form.Floating>
                                        <div className="form-floating mb-1">
                                            <ReactInputMask
                                                placeholder={t("form.phone")}
                                                mask="+7 (999) 999-99-99"
                                                name={t("form.phone")}
                                                type='tel'
                                                id="tel"
                                                className={`form-control ${errors?.phone?.message != null && "is-invalid"}`}
                                                {...register("phone", { required: { value: true, message: "Поле Телефон обязательное" } })}
                                            />
                                            <label htmlFor="tel">{t("form.phone")}</label>
                                            <div className="text-muted ps-1 pr-1 mt-1" style={{ fontSize: '10px', textAlign: 'justify' }}>
                                                {t("form.dectSmsForm")}
                                            </div>
                                            {errors?.phone?.message &&
                                                <div className="invalid-feedback">
                                                    {errors?.phone?.message}
                                                </div>
                                            }
                                        </div>
                                        <div className=" form-check form-switch mb-1">
                                            <label className="form-check-label">{t("payscheme.agreeWith")}<a href='https://leningradcenter.ru/api/redirecturls/privacy-policy' target={"_blank"} >{t("form.agreement")}</a></label>
                                            <input type="checkbox" className="form-check-input check-input-lc"
                                                defaultChecked={agreementChecked}
                                                {...register("policy", { required: { value: true, message: "Необходимо согласие на обработку персональных данных" }, onChange: () => { setAgreementChecked(!agreementChecked) } })}
                                            />
                                            <br />
                                            {errors.policy && <span className='text-danger'>{errors.policy?.message}</span>}
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="pb-3 border-bottom text-center">
                                            <div className="mb-1 fs-5">
                                                <div> Вы авторизованы как </div>
                                                <div><b>{data.lastName} {data.firstName}</b></div>
                                            </div>
                                            <div>
                                                Заказ будут отправлены на: <b>{data.email}</b>
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className="mb-2 d-flex mt-1">
                                    <div className=" form-check form-switch">
                                        <label className="form-check-label">
                                            {t("payscheme.agreeWith")}<a href='https://leningradcenter.ru/api/redirecturls/ticket-purchase-rules' target={"_blank"} >{t("form.ticketRules")}</a> <a href='https://leningradcenter.ru/api/redirecturls/visiting-rules' target={"_blank"} >{t("form.visitingRules")}</a>
                                        </label>
                                        <input type="checkbox" className="form-check-input check-input-lc"
                                            defaultChecked={ticketRulesChecked}
                                            {...register("pravilo",
                                                {
                                                    required: { value: true, message: "Необходимо согласие c правилами обращения подарочных сертификатов" },
                                                    onChange: () => { setTicketRulesChecked(!ticketRulesChecked) }
                                                })}
                                        />
                                        <br />
                                        {errors.pravilo && <span className='text-danger'>{errors.pravilo?.message}</span>}
                                    </div>
                                </div>
                                <div className="mb-2 d-flex">
                                    <div className=" form-check form-switch">
                                        <label className="form-check-label ">{t("form.receiveNewsletter")}</label>
                                        <input type="checkbox" className="form-check-input check-input-lc"
                                            {...register("receiveNewsletter")}
                                        />
                                    </div>
                                </div>
                                {basket.map(item =>
                                    <Fragment key={item.id}>
                                        <div className="border-bottom">
                                            <Row>
                                                <Col xs={'auto'} className="d-flex" style={{ alignItems: "center" }}>
                                                    <div  >
                                                        <Button onClick={() => {
                                                            setDeleteSeatData(item)
                                                        }} className="btn-lc" size="sm" style={{ inlineSize: 17, borderRadius: '100%', padding: 0, width: 24, height: 24 }} > <XCircle size={15} style={{ borderRadius: '100%' }} /></Button>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div style={{ color: item.fill }}>
                                                        {item.categoryName}
                                                    </div>
                                                    <div>
                                                        {item.nameRow}, {item.name}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="flex-fill">
                                                        {item.price} ₽
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Fragment>
                                )}

                            </>
                            :
                            <>
                                <LoadScreenMin />
                            </>}
                        <Row>
                            <Col xs={'auto'}></Col>
                            <Col className="text-end">{t("payscheme.totalSum")}</Col>
                            <Col><div>{summOrder} ₽</div></Col>
                        </Row>
                        {!isCreatingOrder ?
                            <>
                                {!error ?
                                    <Button type="submit" className="btn-lc w-100 mt-3" disabled={(!agreementChecked + !ticketRulesChecked) > 0} size="sm">
                                        {t("payscheme.payment")}
                                    </Button>
                                    :
                                    <div className="text-center">
                                        <div>{error.messageError} ({error.codeError})</div>
                                        <Button onClick={() => { closeError() }} className="btn-lc w-100 mt-3" disabled={(!agreementChecked + !ticketRulesChecked) > 0} size="sm">
                                            Закрыть
                                        </Button>
                                    </div>
                                }
                            </>
                            :
                            <>
                                <Spinner />
                            </>
                        }
                    </Offcanvas.Body>
                    :
                    <OffcanvasBody>
                        <PinCodeForm
                            createOrder={createOrder}
                            t={t}
                            setClientTokenData={setClientTokenData}
                            authData={authData}
                            setDataForToken={setDataForToken}
                            setShowPinInput={setShowPinInput} />
                    </OffcanvasBody>
                }
            </Form>

        </Offcanvas>
    )
}

export const PayTicketsV2 = ({ selLanguage, clientToken, setClientTokenData }) => {

    const { id } = useParams()
    const { data, refetch, isLoading } = useGetPaySchemeQuery({ id, selLng: selLanguage })
    const { data: sessionDetails, isLoadingDetails } = useGetSessionDetailsQuery({ id, selLng: selLanguage })
    const [basket, setBasket] = useState([])
    const [summOrder, setSummOrder] = useState(0)
    const [t] = useTranslation("global");
    const [showOrderModal, setShowOrderModal] = useState(false)
    const [deleteSeatData, setDeleteSeatData] = useState()
    const [schemeSize, setSchemeSize] = useState()
    const [schedules, setSchedules] = useState([])
    const [schedulesModal, setShedulesModal] = useState(false)
    const [categoresModal, setCategoresModal] = useState(false)
    const [soldOut, setSoldOut] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        refetch()
    }, [id])
    useEffect(() => {
        if (basket) {
            const sum = basket.reduce((summa, item) => {
                return summa + (parseFloat(item.price));
            }, 0);
            setSummOrder(sum)
            if (basket.length < 1) {
                setShowOrderModal(false)
            }
        }

    }, [basket])

    useEffect(() => {
    }, [schemeSize])

    useEffect(() => {
        if (sessionDetails) {
            setSchedules(sessionDetails.showSchedules)
        }
    }, [sessionDetails])

    useEffect(() => {
        if (data) {
            var dataStatus = [];
            data.getSchemeHallModel.sections[0].seatsByRows.forEach(element => {
                var dataBooked = element.seats.find(seat => seat.status !== "booked")
                if (dataBooked) {
                    dataStatus.push(dataBooked)
                }
            });
            if (dataStatus.length === 0) {
                // console.log(true)
                setSoldOut(true)
            } else {
                setSoldOut(false)
                // console.log(false)
            }
        }
    }, [data])

    return (
        <>
            {sessionDetails && <>
                {/* <div className="auth-nikolas" style={{ position: "absolute", }}></div> */}
                <div className="haed-layout-tickets d-none d-lg-block">
                    <div className="head-layout-img" style={{ backgroundImage: sessionDetails?.headLayoutImageType ? `url(api/shows/head-wide-img/${sessionDetails?.showId})` : "url(/Show/091A8332.jpg" }}>
                    </div>
                    <div className="head-mask"></div>

                </div>
            </>}
            {showOrderModal &&
                // {true &&

                <OrderModal
                    showOrderModal={showOrderModal}
                    setShowOrderModal={setShowOrderModal}
                    basket={basket}
                    setBasket={setBasket}
                    summOrder={summOrder}
                    setDeleteSeatData={setDeleteSeatData}
                    t={t}
                    clientToken={clientToken}
                    sessionId={id}
                    showName={sessionDetails?.name}
                    startOfEvent={sessionDetails.startOfEvent}
                    hallName={data?.hallName}
                    duration={sessionDetails?.duration}
                    ageCategory={sessionDetails?.ageCategory}
                    setClientTokenData={setClientTokenData}
                />
            }
            {schedulesModal &&
                <SchedulesModal showModal={schedulesModal} t={t} schedules={schedules} setShedulesModal={setShedulesModal} id={id} />
            }
            {
                categoresModal && <CategoresDetails
                    data={data.getCategoriesSeat}
                    selLanguage={selLanguage}
                    showModal={categoresModal}
                    setShowModal={setCategoresModal}
                    t={t}
                />
            }
            <Container>

                <div style={{ paddingTop: "63px" }}></div>

                {!isLoading ?
                    <>
                        {sessionDetails &&
                            <>
                                <Row className="border" style={{ minHeight: '65vh' }}>
                                    <Col>
                                        <div style={{ maxWidth: "220px" }} className="d-none d-lg-block">
                                            <div className="text-center border-bottom pb-2 pt-2">
                                                <span className="text-muted pb-1">{t("payscheme.show")}</span>
                                                <div>
                                                    {sessionDetails?.name}
                                                </div>
                                            </div>
                                            <div className="text-center border-bottom pb-2 pt-2">
                                                <span className="text-muted pb-1">{t("payscheme.venue")}</span>
                                                <div>
                                                    {data?.hallName}
                                                </div>
                                            </div>
                                            <div className="text-center border-bottom pb-2 pt-2">
                                                <span className="text-muted pb-1">{t("payscheme.ageRestrictions")}</span>
                                                <div>
                                                    {sessionDetails?.ageCategory}+
                                                </div>
                                            </div>
                                            <div className="text-center border-bottom pb-2 pt-2">
                                                <span className="text-muted pb-1">{t("payscheme.duration")}</span>
                                                <div>
                                                    {sessionDetails?.duration}
                                                </div>
                                            </div>
                                            <div className="text-center border-bottom pb-2 pt-2">

                                                <span className="text-muted pb-1">{t("payscheme.eventDate")}</span>
                                                <div>
                                                    <div>
                                                        <div>
                                                            <div>
                                                                {format(new Date(sessionDetails?.startOfEvent), 'EEEE', { locale: selLanguage !== "en" ? ru : enUS })},
                                                            </div>
                                                            <div>
                                                                {format(new Date(sessionDetails?.startOfEvent), 'dd')} {format(new Date(sessionDetails?.startOfEvent), 'MMM', { locale: selLanguage !== "en" ? ru : enUS })} {format(new Date(sessionDetails?.startOfEvent), 'yyyy')},
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {t("payscheme.StartedIn")} {format(new Date(sessionDetails?.startOfEvent), 'HH:mm')}
                                                    </div>
                                                </div>
                                                {schedules.length > 1 &&
                                                    <ButtonGroup size="sm" className="w-100 mt-1">
                                                        <Button className="btn-lc" onClick={() => { setShedulesModal(true) }}>
                                                            {t("afisha.selectdate")} <Calendar3Week />
                                                        </Button>
                                                    </ButtonGroup>
                                                }
                                            </div>
                                            <div className="text-center border-bottom fs-5">
                                                {t("payscheme.seatsSelected")}: {basket.length}

                                            </div>
                                            <div className="text-center border-bottom">
                                                {/* <p className="text-muted">Шоу</p> */}
                                                <div>{t("payscheme.total")}: {summOrder}</div>
                                            </div>
                                            <Button className="w-100 btn-lc mt-3 mb-3" disabled={basket.length === 0} onClick={() => setShowOrderModal(true)}>{t("payscheme.placeAnOrder")}</Button>
                                        </div>
                                        <div className="d-lg-none pt-3">
                                            {/* <span className="text-muted pb-1">{t("payscheme.showName")}</span> */}
                                            <div>
                                                <div className="d-flex">
                                                    <h5 className="flex-fill">
                                                        {sessionDetails?.name}
                                                    </h5>
                                                    <div>
                                                        <div>
                                                            <Button onClick={() => { navigate(`/about-project/show/${sessionDetails.seoUrl}`) }} variant="light" size="sm" style={{ inlineSize: 17, borderRadius: '100%', padding: 0, width: 24, height: 24 }} > <XCircle size={15} style={{ borderRadius: '100%' }} /></Button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span className="text-muted pb-1">{t("payscheme.ageRestrictions")}</span>: {sessionDetails?.ageCategory} +
                                                </div>
                                                <div>
                                                    <div>
                                                        <div>
                                                            {format(new Date(sessionDetails?.startOfEvent), 'EEEE', { locale: selLanguage !== "en" ? ru : enUS })}, {format(new Date(sessionDetails?.startOfEvent), 'dd')} {format(new Date(sessionDetails?.startOfEvent), 'MMMM', { locale: selLanguage !== "en" ? ru : enUS })} {format(new Date(sessionDetails?.startOfEvent), 'yyyy')}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {t("payscheme.StartedIn")} {format(new Date(sessionDetails?.startOfEvent), 'HH:mm')}
                                                    </div>
                                                </div>
                                                {/* {sessionDetails?.shortDescription &&
                                                <p className="text-muted">
                                                    {sessionDetails?.shortDescription}
                                                </p>
                                            } */}
                                                {schedules.length > 1 &&
                                                    <ButtonGroup size="sm" className="w-100 mt-1 mb-1">
                                                        <Button variant="light" onClick={() => { setShedulesModal(true) }}>
                                                            {t("afisha.selectdate")} <Calendar3Week />
                                                        </Button>
                                                    </ButtonGroup>
                                                }

                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={8} className="position-relative" >
                                        {!isLoadingDetails ?
                                            <>
                                                {soldOut && <div className={"soldOut"}></div>}
                                                {/* <PayScheme
                                                    schemeSize={schemeSize}
                                                    setSchemeSize={setSchemeSize}
                                                    data={data.getSchemeHallModel}
                                                    categoriesSeat={data.getCategoriesSeat}
                                                    basket={basket}
                                                    setBasket={setBasket}
                                                    deleteSeatData={deleteSeatData}
                                                    setDeleteSeatData={setDeleteSeatData}
                                                    selLng={selLanguage}
                                                // sessionDetails={sessionDetails}
                                                /> */}
                                                {data ?
                                                    <StageV2
                                                        schemeSize={schemeSize}
                                                        setSchemeSize={setSchemeSize}
                                                        data={data?.getSchemeHallModel}
                                                        categoriesSeat={data?.getCategoriesSeat}
                                                        basket={basket}
                                                        setBasket={setBasket}
                                                        deleteSeatData={deleteSeatData}
                                                        setDeleteSeatData={setDeleteSeatData}
                                                        selLng={selLanguage}
                                                    // sessionDetails={sessionDetails}
                                                    />
                                                    :
                                                    <></>
                                                }
                                            </>
                                            :
                                            <LoadScreenMin />
                                        }
                                    </Col>
                                    <Col className="d-none d-lg-block">
                                        <div style={{ maxHeight: schemeSize, overflowY: 'auto' }}>
                                            {data && <>
                                                {data?.getCategoriesSeat.length > 0 && data.getCategoriesSeat.map(
                                                    category =>
                                                        <div style={{ maxWidth: "220px" }} key={category.id} className="border-bottom pt-2 pb-2">
                                                            <div style={{ color: category.fill, alignItems: 'center' }} className="d-flex">
                                                                <div style={{ width: "16px", height: "16px", background: category.fill, borderRadius: '2px 2px 5px 5px' }} className="me-2">
                                                                </div>{selLanguage === 'en' ? category.nameEn : category.name}</div>
                                                            <div>{category.price} ₽</div>
                                                            <div style={{ fontSize: "12px" }}>
                                                                {selLanguage === 'en' ? category.descriptionEn : category.description}
                                                            </div>
                                                        </div>
                                                )}
                                            </>}
                                        </div>
                                    </Col>
                                    <Col className="d-lg-none">
                                        <div>
                                            {data?.getCategoriesSeat.map(item =>
                                                <div style={{ display: 'inline-flex' }} className="ms-2" key={item.id}>
                                                    <div style={{ display: 'inline-flex' }}>
                                                        <div style={{ display: 'grid', alignItems: 'center' }}>
                                                            <span className={styles.seatDote} style={{ backgroundColor: item.fill }}>
                                                            </span>
                                                        </div>
                                                        <div>
                                                            {/* <div>{item.name}</div> */}
                                                            <div>{item.price} ₽</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <ButtonGroup size="sm" className="w-100 mt-1">
                                                <Button variant="light" onClick={() => { setCategoresModal(true) }}>
                                                    {t("payscheme.whatinPrice")} <QuestionCircle />
                                                </Button>
                                            </ButtonGroup>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        }
                    </>
                    :
                    <LoadScreenMin height={40} />
                }

            </Container >
            <div className="position-fixed p-2 w-100 bg-white d-lg-none text-center" style={{ bottom: 0, zIndex: 1000 }}>
                <div className="justify-content-around d-flex mb-2">
                    <div>{t("payscheme.seatsSelected")}: {basket.length}</div>
                    <div>{t("payscheme.total")}: <b>{summOrder}</b></div>
                </div>
                <Button className='border btn-afisha w-100' disabled={basket.length < 1} style={{ borderColor: "#272929" }} onClick={() => {
                    setShowOrderModal(true)
                }} variant='light'>{t("afisha.buytickets")}</Button>
            </div>

        </>
    )
}